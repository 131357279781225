import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// Create an HTTP link
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT, // Ensure this is correctly set
});

// Create an auth link
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  console.log("Using token:", token); // Debug log
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Create an Apollo client with links and cache
const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

// Apollo Provider component
export function ApolloAuthProvider({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
