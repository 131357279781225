import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useQuery, useMutation, gql } from "@apollo/client";
import AddFamilyMemberForm from "../components/AddFamilyMemberForm";
import MemberDetails from "../components/MemberDetails";
import Search from "../components/Search";
import { IoMdClose } from "react-icons/io";
import { FaUserPlus } from "react-icons/fa6";

Modal.setAppElement("#root");

const GET_MEMBERS = gql`
  query GetMembers {
    familyMembers(pagination: { limit: 40 }) {
      data {
        id
        attributes {
          name
          parent {
            data {
              id
              attributes {
                name
              }
            }
          }
          children {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const DELETE_MEMBER = gql`
  mutation DeleteMember($id: ID!) {
    deleteFamilyMember(id: $id) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

function FamilyMembers({ isAuthenticated, username, userRole }) {
  const { loading, error, data, refetch } = useQuery(GET_MEMBERS);
  const [deleteMemberMutation] = useMutation(DELETE_MEMBER, {
    onCompleted: () => refetch(), // Refetch members after deletion
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [clickedCell, setClickedCell] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [rootMemberName, setRootMemberName] = useState("");

  console.log("Family members", userRole);
  console.log("Family members", username);

  useEffect(() => {
    if (data?.familyMembers?.data) {
      console.log("All Members:", data.familyMembers.data);
      const lowercasedTerm = searchTerm.toLowerCase();
      const filtered = data.familyMembers.data.filter(
        (member) =>
          member.attributes.name.toLowerCase().includes(lowercasedTerm) ||
          member.id.toLowerCase().includes(lowercasedTerm)
      );
      console.log("Filtered members after applying search:", filtered);
      setFilteredMembers(filtered);

      // Find the root member (a member without a parent)
      const rootMember = data.familyMembers.data.find(
        (member) => !member.attributes.parent?.data
      );

      if (rootMember) {
        setRootMemberName(rootMember.attributes.name);
      }

      // If the search term matches a member ID or name, select that member
      const foundMember = filtered.find(
        (member) =>
          member.id.toLowerCase() === lowercasedTerm ||
          member.attributes.name.toLowerCase() === lowercasedTerm
      );
      if (foundMember) {
        setSelectedMember(foundMember);
        setClickedCell({ id: foundMember.id, cellType: "parent" });
      }
    }
    console.log("Raw data from GET_MEMBERS:", data);
  }, [searchTerm, data]);

  const handleMemberClick = (member, cellType) => {
    setSelectedMember(member);
    setClickedCell({ id: member.id, cellType });
  };

  const deleteMember = async (id) => {
    if (
      window.confirm(`Are you sure you want to delete member of id: ${id}?`)
    ) {
      try {
        await deleteMemberMutation({ variables: { id: id } });
      } catch (error) {
        console.error("Error deleting member:", error);
        alert("There was an error deleting the member.");
      }
    }
  };

  if (loading) return <p className="text-center text-lg">Loading...</p>;
  if (error)
    return (
      <p className="text-center text-lg text-red-500">
        Error fetching data: {error.message}
      </p>
    );

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const buildRows = () => {
    if (!filteredMembers) return null;

    const members = filteredMembers;
    console.log("Members to be rendered:", members);

    // Find the root member (members without a parent)
    const rootMember = members.find(
      (member) => !member.attributes.parent?.data
    );

    if (!rootMember && members.length === 0) {
      return (
        <tr>
          <td
            colSpan="2"
            className="py-2 px-4 border-2 border-gray-200 text-center"
          >
            No members found. Please add a root member first.
          </td>
        </tr>
      );
    }

    // Build the rows with root member first
    return (
      <>
        {rootMember && (
          <tr key={rootMember.id}>
            <td
              className="text-sm py-2 px-4 border border-gray-700 font-semibold"
              onClick={() => handleMemberClick(rootMember, "parent")}
              style={{
                cursor: "pointer",
                backgroundColor:
                  clickedCell?.id === rootMember.id &&
                  clickedCell?.cellType === "parent"
                    ? "#f2f2f2"
                    : "transparent",
                color:
                  clickedCell?.id === rootMember.id &&
                  clickedCell?.cellType === "parent"
                    ? "black"
                    : "#333",
              }}
            >
              {rootMember.attributes.name}
            </td>
            <td className="text-sm py-2 px-4 border border-gray-700 font-semibold">
              {rootMember.attributes.children?.data.map((child) => (
                <div
                  key={child.id}
                  onClick={() => handleMemberClick(child, "child")}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      clickedCell?.id === child.id &&
                      clickedCell?.cellType === "child"
                        ? "#f2f2f2"
                        : "transparent",
                    color:
                      clickedCell?.id === child.id &&
                      clickedCell?.cellType === "child"
                        ? "black"
                        : "#333",
                    marginBottom: "10px",
                  }}
                >
                  {child.attributes.name}
                </div>
              ))}
            </td>
          </tr>
        )}
        {members
          .filter(
            (member) =>
              member.attributes.parent?.data && // Ensure the member is not the root member
              member.attributes.children?.data.length > 0 // Ensure the member has children
          )
          .map((member) => {
            const { id, attributes } = member;
            const name = attributes?.name || "Unknown";
            const children = attributes?.children?.data || [];

            return (
              <React.Fragment key={id}>
                <tr>
                  <td
                    className="text-sm py-2 px-4 border border-gray-700 font-semibold"
                    onClick={() => handleMemberClick(member, "parent")}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        clickedCell?.id === id &&
                        clickedCell?.cellType === "parent"
                          ? "#f2f2f2"
                          : "transparent",
                      color:
                        clickedCell?.id === id &&
                        clickedCell?.cellType === "parent"
                          ? "black"
                          : "#333",
                    }}
                  >
                    {name}
                  </td>
                  <td className="text-sm py-2 px-4 border border-gray-700 font-semibold">
                    {children.map((child) => (
                      <div
                        key={child.id}
                        onClick={() => handleMemberClick(child, "child")}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            clickedCell?.id === child.id &&
                            clickedCell?.cellType === "child"
                              ? "#f2f2f2"
                              : "transparent",
                          color:
                            clickedCell?.id === child.id &&
                            clickedCell?.cellType === "child"
                              ? "black"
                              : "#333",
                          marginBottom: "10px",
                        }}
                      >
                        {child.attributes.name}
                      </div>
                    ))}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
      </>
    );
  };

  // Count unique parents
  const allParents = new Set();
  console.log("All parents:", allParents);
  filteredMembers.forEach((member) => {
    if (!member.attributes.parent?.data) {
      allParents.add(member.id);
    } else {
      // Add the parent to the set
      allParents.add(member.attributes.parent.data.id);
    }
  });

  const totalFamilies = allParents.size;

  return (
    <div className="container px-4 lg:w-4/5 lg:px-0 mx-auto">
      {data.familyMembers.data.length >= 1 && (
        <h1 className="mb-5 text-xl md:text-2xl font-medium text-center">
          Geneology Of {rootMemberName}
        </h1>
      )}
      {/* Search Component */}
      {data.familyMembers.data.length >= 1 && (
        <Search
          className="w-full xl:w-4/5"
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
        />
      )}
      {/* Families Count */}
      <p className="text-sm font-medium mb-4">
        {totalFamilies}{" "}
        {totalFamilies === 1 ? "family found" : "families found"}
      </p>
      {/* Conditionally render the Add Family Member button */}
      {!data?.familyMembers?.data?.some(
        (member) => !member.attributes.parent?.data
      ) && (
        <button
          variant="secondary"
          className="flex items-center text-sm px-4 py-2 rounded border mb-4 bg-indigo-100 hover:bg-gray-200 transition duration-300"
          onClick={openModal}
          title="Add root member"
        >
          <FaUserPlus className="size-4 mr-2" />
          Add root member
        </button>
      )}
      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Family Member"
        className="fixed inset-0 bg-white w-full mx-auto my-4 p-6 border max-w-lg border-gray-300 rounded-lg shadow-lg max-height-[400px] overflow-y-scroll"
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50"
      >
        <AddFamilyMemberForm
          parentName={selectedMember?.attributes?.name || ""}
          onSuccess={() => {
            refetch();
            closeModal();
          }}
        />
        <button
          onClick={closeModal}
          className="mt-4 bg-gray-300 text-white px-4 py-2 rounded hover:bg-gray-400"
        >
          <IoMdClose />
        </button>
      </Modal>
      {data.familyMembers.data.length >= 1 && (
        <div className="block lg:flex gap-5">
          <div
            className="w-full mb-5 lg:w-1/3 bg-white-100 overflow-y-scroll"
            style={{ height: "fit-content", maxHeight: "600px" }}
          >
            <table
              className={loading ? `animate-pulse` : `w-full border-collapse`}
            >
              <thead className="bg-gray-400">
                <tr>
                  <th className="py-2 px-4 border-2 border-gray-200 bg-gray-200 font-semibold">
                    Parent
                  </th>
                  <th className="py-2 px-4 border-2 border-gray-200 bg-gray-200 font-semibold">
                    Children
                  </th>
                </tr>
              </thead>
              <tbody>{buildRows()}</tbody>
            </table>
          </div>
          {/* Member Details */}
          <div className="w-full lg:w-2/3 bg-white-100 lg:mt-0 mt-5">
            {selectedMember ? (
              <MemberDetails
                memberId={selectedMember?.id}
                member={selectedMember}
                isAuthenticated={isAuthenticated}
                userRole={userRole}
                username={username}
                onDeleteMember={deleteMember}
              />
            ) : (
              <p className="text-center text-lg">
                Select a member to view details
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default FamilyMembers;
