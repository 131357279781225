import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import logoImg from "../images/fav.png";

const Header = ({ handleLogout, isAuthenticated, username }) => {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    const confirmed = window.confirm("Are you sure you want to logout?");
    if (confirmed) {
      handleLogout();
      navigate("/login");
    }
  };

  console.log("Username in Header:", username);
  return (
    <header className="bg-indigo-100  py-2 mb-10">
      <div className="flex flex-wrap justify-between items-center mx-auto px-4 lg:px-0 lg:w-4/5">
        <h1 className="text-xl font-normal">
          <Link
            className="hover:no-underline active:text-gray-100 active:no-underline flex justify-center gap-2 items-center "
            to="/family-members"
          >
            <img className="w-8 h-8" src={logoImg} alt="logo" />
            <span className="hidden lg:block text-lg font-semibold">
              Family.io
            </span>
          </Link>
        </h1>

        {isAuthenticated && (
          <div className="text-center font-semibold text-sm xl:text-sm md:text-left flex justify-between items-center">
            <p className="mr-2">{username}</p>
            <IoLogOutOutline
              title="Logout"
              onClick={handleLogoutClick}
              className="size-5 cursor-pointer"
            />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
