import React from "react";

const Search = ({ searchTerm, onSearchChange }) => {
  return (
    <div className="search-container mb-4">
      <input
        type="text"
        placeholder="Search by ID or Name"
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value)}
        className="search-input border px-4 py-2 text-xs md:text-sm w-full lg:w-auto xl:w-[400px] rounded-full"
      />
    </div>
  );
};

export default Search;
