import React from "react";
import { useFormik } from "formik";
import { getStrapiURL } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure this is imported
import logo from "../images/fav.png";

const LoginForm = ({ setIsAuthenticated, setUsername }) => {
  const navigate = useNavigate();

  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      identifier: "",
      password: "",
    },
    onSubmit: async (values) => {
      try {
        const url = getStrapiURL("/auth/local");

        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        const responseData = await res.json();

        if (!res.ok) {
          const errorMessage =
            responseData.error?.message || `HTTP error! status: ${res.status}`;
          throw new Error(errorMessage);
        }

        const { jwt, user } = responseData;

        // Save the token and username to local storage
        localStorage.setItem("token", jwt);
        localStorage.setItem("username", user.username || "");

        // Fetch the user's role based on their ID
        const roleUrl = getStrapiURL(`/users/${user.id}?populate=role`);
        const roleRes = await fetch(roleUrl, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        const roleData = await roleRes.json();
        const role =
          roleData.role?.name || roleData.role?.type || "No role found";

        localStorage.setItem("userRole", role);

        // Update authentication state and username
        setIsAuthenticated(true);
        setUsername(user.username); // Update the username in the App component

        navigate("/family-members");
        toast.success("Login successful");
      } catch (error) {
        console.error("Login error:", error);
        toast.error(error.message || "An unexpected error occurred");
      }
    },
  });

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-indigo-100 ">
      <div className="w-1/4 h-full login-bg"></div>
      <img
        className="w-[70px] max-w-[100px] h-auto mb-4 z-20 border-x-lime-700"
        src={logo}
        alt="logo"
      />

      <div className="w-4/5 mx-auto max-w-[300px] p-8 bg-white shadow-lg rounded-lg">
        <h1 className="text-2xl font-bold mb-6 text-center">Login</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="form-group">
            <label
              htmlFor="identifier"
              className="block text-sm font-medium text-gray-700"
            >
              Username/Email
            </label>
            <input
              type="text"
              name="identifier"
              id="identifier"
              placeholder="Enter your username or email"
              onChange={handleChange}
              value={values.identifier}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="form-group">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Enter your password"
              onChange={handleChange}
              value={values.password}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
