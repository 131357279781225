import React, { useState, useEffect } from "react";
import { useMutation, gql, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ADD_FAMILY_MEMBER = gql`
  mutation CreateFamilyMember($data: FamilyMemberInput!) {
    createFamilyMember(data: $data) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

const GET_FAMILY_MEMBERS = gql`
  query GetFamilyMembers {
    familyMembers {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

function AddFamilyMemberForm({ parentInfo = null, onSuccess, closeModal }) {
  const [name, setName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [parentId, setParentId] = useState(null);
  const [isAlive, setIsAlive] = useState(true);
  const [dateOfDeath, setDateOfDeath] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [occupation, setOccupation] = useState("");
  const [biographicalInformation, setBiographicalInformation] = useState("");

  const [addFamilyMember, { loading, error }] = useMutation(ADD_FAMILY_MEMBER, {
    onCompleted: () => {
      if (onSuccess) onSuccess();
      resetForm();
      toast.success("Family member added successfully!", { autoClose: 3000 });
      if (closeModal) closeModal(); // Close the modal after success
    },
    refetchQueries: [{ query: GET_FAMILY_MEMBERS }], // Refetch the family members query
    onError: (err) => {
      console.error("Mutation error:", err);
      toast.error("Failed to add family member. Please try again.", {
        autoClose: 3000,
      });
    },
  });

  const { data: membersData } = useQuery(GET_FAMILY_MEMBERS);

  useEffect(() => {
    if (parentInfo && parentInfo.name) {
      const parent = membersData?.familyMembers.data.find(
        (member) => member.attributes.name === parentInfo.name
      );
      if (parent) setParentId(parent.id);
    }
  }, [parentInfo, membersData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const mutationVariables = {
      data: {
        name,
        date_of_birth: dateOfBirth,
        gender,
        is_alive: isAlive,
        date_of_death: !isAlive ? dateOfDeath : null,
        place_of_birth: placeOfBirth,
        occupation,
        biographical_information: biographicalInformation,
        parent: parentId ? parentId : null,
      },
    };

    console.log("Submitting mutation with variables:", mutationVariables);

    try {
      await addFamilyMember({ variables: mutationVariables });
    } catch (error) {
      console.error("Mutation error:", error);
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message, locations, path }) => {
          console.error(
            `GraphQL error: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      }
    }
  };

  const resetForm = () => {
    setName("");
    setDateOfBirth("");
    setGender("");
    setIsAlive(true);
    setDateOfDeath("");
    setPlaceOfBirth("");
    setOccupation("");
    setBiographicalInformation("");
    setParentId(null);
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4 text-center">Add Family Member</h2>
      <form onSubmit={handleSubmit} className="w-500 space-y-4 ">
        <div className="form-group">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="dateOfBirth"
            className="block text-sm font-medium text-gray-700"
          >
            Date of Birth
          </label>
          <input
            type="date"
            id="dateOfBirth"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="gender"
            className="block text-sm font-medium text-gray-700"
          >
            Gender
          </label>
          <select
            id="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          >
            <option value="">Select gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className="form-group">
          <label
            htmlFor="isAlive"
            className="block text-sm font-medium text-gray-700"
          >
            Is Alive
          </label>
          <input
            type="checkbox"
            id="isAlive"
            checked={isAlive}
            onChange={(e) => setIsAlive(e.target.checked)}
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded px-2 py-4"
          />
        </div>
        {!isAlive && (
          <div className="form-group">
            <label
              htmlFor="dateOfDeath"
              className="block text-sm font-medium text-gray-700"
            >
              Date of Death
            </label>
            <input
              type="date"
              id="dateOfDeath"
              value={dateOfDeath}
              onChange={(e) => setDateOfDeath(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
            />
          </div>
        )}
        <div className="form-group">
          <label
            htmlFor="placeOfBirth"
            className="block text-sm font-medium text-gray-700"
          >
            Place of Birth
          </label>
          <input
            type="text"
            id="placeOfBirth"
            value={placeOfBirth}
            onChange={(e) => setPlaceOfBirth(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="occupation"
            className="block text-sm font-medium text-gray-700"
          >
            Occupation
          </label>
          <input
            type="text"
            id="occupation"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="biographicalInformation"
            className="block text-sm font-medium text-gray-700"
          >
            Biographical Information
          </label>
          <textarea
            id="biographicalInformation"
            value={biographicalInformation}
            onChange={(e) => setBiographicalInformation(e.target.value)}
            cols={83}
            rows={5}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="parent"
            className="block text-sm font-medium text-gray-700"
          >
            Parent
          </label>
          <input
            type="text"
            id="parent"
            value={parentInfo ? parentInfo.name : ""}
            readOnly
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group text-center">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {loading ? "Submitting..." : "Add Member"}
          </button>
        </div>
        {error && <p className="text-red-500">{error.message}</p>}
      </form>
    </div>
  );
}

export default AddFamilyMemberForm;
