import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import FamilyMembers from "./pages/FamilyMembers";
import LoginForm from "./pages/LoginForm";
import { ToastContainer, toast } from "react-toastify";
import { ApolloAuthProvider } from "./components/ApolloAuthProvider";
import "react-toastify/dist/ReactToastify.css";
import { MainLayout } from "./components/MainLayout";
import HomePage from "./pages/HomePage";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [userRole, setUserRole] = useState("Public");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const storedUsername = localStorage.getItem("username");
    const storedUserRole = localStorage.getItem("userRole");

    if (token && storedUsername && storedUserRole) {
      setIsAuthenticated(true);
      setUsername(storedUsername);
      setUserRole(storedUserRole);
    } else {
      // If any of the required items are missing, clear all authentication data
      handleLogout();
    }
  }, []);

  const handleLogin = (token, username, role) => {
    localStorage.setItem("token", token);
    localStorage.setItem("username", username);
    localStorage.setItem("userRole", role);
    setIsAuthenticated(true);
    setUsername(username);
    setUserRole(role);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("userRole");
    setIsAuthenticated(false);
    setUsername("");
    setUserRole("Public");
    toast.success("Logged out successfully");
  };

  return (
    <Router>
      <ApolloAuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <Navigate to={isAuthenticated ? "/family-members" : "/login"} />
            }
          />
          <Route path="/home" element={<HomePage userRole={userRole} />} />
          <Route
            path="/login"
            element={
              <LoginForm
                setIsAuthenticated={setIsAuthenticated}
                setUsername={setUsername}
                setUserRole={setUserRole}
                handleLogin={handleLogin}
              />
            }
          />
          <Route
            path="/family-members"
            element={
              isAuthenticated ? (
                <MainLayout
                  handleLogout={handleLogout}
                  isAuthenticated={isAuthenticated}
                  username={username}
                  userRole={userRole}
                >
                  <FamilyMembers
                    isAuthenticated={isAuthenticated}
                    username={username}
                    userRole={userRole}
                  />
                </MainLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </ApolloAuthProvider>
      <ToastContainer autoClose={3000} />
    </Router>
  );
};

export default App;
